



























































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { Ax } from '@/utils';
import { eadToValueListMap, individualEadKeys, eadValidateKeysListMap, hideEadKeys } from '../common';
import {AddAttrField, ListItem, ListItemPart, Overlay} from '../components';
import I18n from '../I18n';
import { Comp, Dict, Ead as EadNs, Version } from '../types';
import StEad = Dict.StEad;
import {BIcon, BootstrapVueIcons} from 'bootstrap-vue'
Vue.use(BootstrapVueIcons)

// region Local types
type TDefaultValue = null | boolean | string | number;
// endregion


// region Utils
const i18n = new I18n('modules.budget.staffing_table.config.*Ead*');

// noinspection SpellCheckingInspection
const allowedTypes: string[] = ['BOOL', 'DATE', 'NORMATIVE', 'NUMBER', 'SALARY_RATE', 'TEXT'];
// endregion


@Component({
    components: {
        ListItemPart,
        ListItem,
        'b-icon': BIcon,
        'add-attr-field': AddAttrField,
        'overlay': Overlay,
    }
})
export default class Ead extends Vue {
    // region Свойства
    @Prop({
        type: Object,
        required: true,
    })
    public version!: Version;
    // endregion


    // region Утилиты
    private i18n = i18n;

    private toast(type: 'danger' | 'warning' | 'success', title: string, message: string) {
        this.$bvToast.toast(message, {
            title: title,
            variant: type,
            toaster: 'b-toaster-top-center',
            autoHideDelay: 5000,
            appendToast: true
        });
    }
    // endregion


    // region Lifecycle
    // noinspection JSUnusedLocalSymbols
    private created() {
        this.$watch('version', () => {
            this.reload();
        });
        this.$watch('defaultValueMap', () => {
            this.defaultErrors.clear();
            for (const code of this.originalKeyMap.keys()) {
                if (eadValidateKeysListMap.has(code)) {
                    const valueError = eadValidateKeysListMap?.get(code);
                    const valueInput = this.defaultValueMap?.get(code);

                    if (valueError.min === null && typeof valueInput === 'number') {
                        if (valueInput > valueError.max) {
                            this.defaultErrors.set(code, this.$t(`modules.budget.staffing_table.config.*Ead*.error.${valueError.error}`) + valueError.max + '%');
                        }
                    }

                    if (valueError.max === null && typeof valueInput === 'number') {
                        if (valueInput < valueError.min) {
                            this.defaultErrors.set(code, this.$t(`modules.budget.staffing_table.config.*Ead*.error.${valueError.error}`) + valueError.min + '%');
                        }
                    }
                }
            }
        });
    }

    // noinspection JSUnusedLocalSymbols
    private mounted() {
        this.loadEad(() => {
            this.reload();
        });
    }
    // endregion


    // region Данные
    private loading = false;

    private stEadList: Dict.StEad[] = []

    private allKeys: string[] = [];

    private keysStEadNormative: string[] =[];

    private groupedStEad(): { kind: string; stEadS: StEad[] }[] {
        const groups: { [key: string]: StEad[] } = {};

        this.stEadList.forEach(stEad => {
            const kind = stEad.kind;
            if (!groups[kind]) {
                groups[kind] = [];
            }
          if (hideEadKeys.notIncludes(stEad.code)) {
              groups[kind].push(stEad);
          }
        });

        return Object.keys(groups).map(kind => ({
            kind,
            stEadS: groups[kind]
        }));
    }

    private checkAllowedTypes = (type: string): boolean => {
        const index = allowedTypes.indexOf(type);
        return (index >= 0);
    };

    private initialKeyMap = (): Map<string, boolean> => {
        const result = new Map<string, boolean>();
        this.allKeys.forEach(code => {
            result.set(code, false);
        });
        return result;
    };

    private eadToMap: Map<string, Dict.StEad> = new Map<string, Dict.StEad>();

    private keyMap: Map<string, boolean> = new Map<string, boolean>();

    private originalKeyMap: Map<string, boolean> = new Map<string, boolean>();

    private defaultValueMap: Map<string, TDefaultValue> = new Map<string, TDefaultValue>();

    private defaultErrors: Map<string, TDefaultValue> = new Map<string, TDefaultValue>();

    private originalDefaultValueMap: Map<string, TDefaultValue> = new Map<string, TDefaultValue>();

    private originalDefaultValueMapForAllowRate: Map<string, boolean | null> = new Map<string, boolean | null>();

    private defaultValueMapForAllowRate: Map<string, boolean | null> = new Map<string, boolean | null>();

    private get changed(): boolean {
        if (this.keyMap.size !== this.originalKeyMap.size) {
            return true;
        }
        if (this.defaultValueMap.size !== this.originalDefaultValueMap.size) {
            return true;
        }

        for (const code of this.allKeys) {
            if (this.keyMap.get(code) !== this.originalKeyMap.get(code)) {
                return true;
            }
        }

        for (const entry of this.defaultValueMap) {
            const code = entry[0];
            const value = entry[1];
            const originalValue = this.originalDefaultValueMap.get(code);
            if (value !== originalValue) {
                return true;
            }
        }

        for (const entry of this.defaultValueMapForAllowRate) {
            const code = entry[0];
            const value = entry[1];
            const originalValue = this.originalDefaultValueMapForAllowRate.get(code);
            if (value !== originalValue) {
                return true;
            }
        }

        return false;
    }

    private getChecked(key: string): boolean {
        return (this.keyMap.get(key) === true);
    }

    private getCheckedAllowRate(key: string): boolean {
        return (this.defaultValueMapForAllowRate.get(key) === true);
    }

    private isSeasonWorkActive(){
        const value = this.keyMap.get('SEASONAL_WORKER')
        if (value) {
            this.keyMap.set('SEASONAL_WORKER_END', true)
        } else this.keyMap.set('SEASONAL_WORKER_END', false)
    }

    private checkNormativeBdoMrp(normIndCode: null | string): boolean  {
        return  normIndCode === 'BDO' || normIndCode === 'MRP';
    }

    private toggleChecked(code: string) {
        const value = !this.keyMap.get(code);
        this.keyMap.set(code, value);
        this.keyMap = new Map(this.keyMap);
        if (this.keyMap.get(code) === false) { this.defaultValueMap.delete(code); }

        if (this.keysStEadNormative.includes(code)) {
            this.defaultValueMapForAllowRate.has(code) ? this.defaultValueMapForAllowRate.delete(code) : this.defaultValueMapForAllowRate.set(code, value);
        }
    }

    // noinspection JSMethodCanBeStatic
    private getType(code: string): Dict.StEad.AddAttrType | undefined {
        const dictStEad: (Dict.StEad | undefined) = this.eadToMap.get(code);
        // noinspection JSIncompatibleTypesComparison
        if (dictStEad !== undefined) {
            return dictStEad.attrType;
        }
        return undefined;
    }

    // noinspection JSMethodCanBeStatic
    private getIDictStEad(code: string): Dict.StEad {
        return this.eadToMap.get(code)!!
    }

    private getAllowRate(code: string): boolean {
        return this.defaultValueMapForAllowRate.get(code) ?? true
    }

    // noinspection JSMethodCanBeStatic
    private getEadText(stEad: Dict.StEad): string  {
        if (this.isKazakh){
           String(stEad.nameKk)
        }
        return String(stEad.nameRu)
    }

    private get isKazakh(): boolean {
        return (this.$i18n.locale.trim().toLowerCase() === 'kk');
    }

    // noinspection JSMethodCanBeStatic
    private getOptions(code: string): Array<Comp.DropdownItemDef<null | boolean | string | number | Date>> | null {
        return eadToValueListMap.get(code) ?? null;
    }

    // noinspection JSMethodCanBeStatic
    private isInputFieldVisible(code: string): boolean {
        // http://192.168.0.146/issues/4537
        // Надо убрать окно "Пособие единовременное при увольнении ....."
        // noinspection SpellCheckingInspection
        if (code === 'BF_LSADSASPJRADETODMSC') return false;
        return (!individualEadKeys.includes(code));
    }

    private isEadNormative(code: string): boolean {
        return this.defaultValueMapForAllowRate.has(code);
    }

    private getDefaultValue(code: string): TDefaultValue | Date {
        const result = this.defaultValueMap.get(code) ?? null;
        if ((typeof result === 'number') && (this.getType(code) === 'DATE')) {
            return new Date(result);
        }
        return result;
    }

    private setDefaultValue(code: string, value: TDefaultValue | Date) {
        const defaultValueMap = new Map(this.defaultValueMap);
        if (value instanceof Date) {
            defaultValueMap.set(code, value.getTime());
        } else {
            defaultValueMap.set(code, value);
        }
        this.defaultValueMap = defaultValueMap;
    }

    private setNormValue(code: string, value: boolean) {
        const defaultValueMapForAllowRate = new Map(this.defaultValueMapForAllowRate);
        defaultValueMapForAllowRate.set(code, value)
        this.defaultValueMapForAllowRate = defaultValueMapForAllowRate
    }

    private scrollToSection() {
        const section = document.getElementById('scroll');
        if (section) {
            section.scrollIntoView({ behavior: 'smooth' });
        }
    }

    private loadEad(next?: () => void) {
        if (this.loading) {
            console.error('Another loading is running, current loading cancelled');
            return;
        }

        Ax<Dict.StEad[]>(
            {
                url: `/api/budget/staffing_table/dict-st-ead/list`
            },
            data => {
                this.stEadList = data
                const result = new Map<string, Dict.StEad>();
                const eadNormative: string[] = [];
                data.forEach(value => {
                    result.set(value.code, value)

                    if (this.checkNormativeBdoMrp(value.dictNormIndCode)) {
                        eadNormative.push(value.code)
                    }
                });
                const allKeys: string[] = [];
                result.forEach((value, code) => {
                    allKeys.push(code);
                });

                this.keysStEadNormative = eadNormative
                this.allKeys = allKeys
                this.eadToMap = result;
            },
            error => this.toast('danger', this.i18n.translate('error.cannot_load_items'), error.toString()),
            () => {
                this.loading = false;
                if (next !== undefined) next();
            }
        );
    }

    private reload() {
        if (this.loading) {
            console.error('Another loading is running, current loading cancelled');
            return;
        }

        const versionId = this.version.id;
        if (versionId === null) {
            console.error('Version ID is null, cannot load config');
            return;
        }

        this.loading = true;

        Ax<Array<EadNs.EadC>>(
            {
                url: `/api/budget/staffing_table/config/${versionId}/ead-c/list`
            },
            data => {
                const keyMap = this.initialKeyMap();
                const defaultValueMap: Map<string, TDefaultValue> = new Map();
                const defaultValueMapForAllowRate: Map<string, boolean | null> = new Map();

                data.forEach(value => {
                    const code = value.ead!!.code;
                    const normIndCode = value.ead!!.dictNormIndCode;

                    keyMap.set(code, true)

                    if (this.checkNormativeBdoMrp(normIndCode)) {
                        defaultValueMapForAllowRate.set(code, value.allowRate)
                    }

                    let defaultValue: TDefaultValue = null;
                    switch (this.getType(code)) {
                        case 'BOOL':
                            defaultValue = value.valueBool;
                            break;
                        case 'DATE':
                            defaultValue = value.valueDate;
                            break;
                        case 'NORMATIVE':
                            defaultValue = value.valueNormRate;
                            break;
                        case 'NUMBER':
                            defaultValue = value.valueNumber;
                            break;
                        case 'SALARY_RATE':
                            defaultValue = value.valueSalaryRate;
                            break;
                        case 'TEXT':
                            defaultValue = value.valueText;
                            break;
                        default:
                            break;
                    }
                    defaultValueMap.set(code, defaultValue);
                });

                this.keyMap = keyMap;
                this.originalKeyMap = new Map(keyMap);
                this.defaultValueMap = defaultValueMap;
                this.originalDefaultValueMap = new Map(defaultValueMap);
                this.defaultValueMapForAllowRate = defaultValueMapForAllowRate
                this.originalDefaultValueMapForAllowRate = new Map(defaultValueMapForAllowRate);
            },
            error => this.toast('danger', this.i18n.translate('error.cannot_load_items'), error.toString()),
            () => {
                this.loading = false;
            }
        );
    }

    private reset() {
        this.keyMap = new Map(this.originalKeyMap);
        this.defaultValueMap = new Map(this.originalDefaultValueMap);
        this.defaultValueMapForAllowRate = new Map(this.originalDefaultValueMapForAllowRate)
    }

    private save() {
        if (this.loading) {
            console.error('Cannot save data - another loading is running');
            return;
        }

        // Проверка доп.атр сезонный рабочий
        this.isSeasonWorkActive();

        const versionId = this.version.id;
        if (versionId === null) {
            console.log('Cannot save data - version ID is null');
            return;
        }

        const data: Array<EadNs.EadC> = [];
        this.keyMap.forEach((value, code) => {
            if (value) {
                const stEad = this.getIDictStEad(code);
                const allowRate = this.getAllowRate(code)

                const item: EadNs.EadC = {
                    id: null,
                    ead: stEad,
                    valueBool: null,
                    valueText: null,
                    valueNumber: null,
                    valueSalaryRate: null,
                    valueNormRate: null,
                    valueDate: null,
                    allowRate: allowRate,
                    version: this.version,
                };

                const type = this.getType(code);
                const defaultValue = this.getDefaultValue(code);
                if (defaultValue !== null) {
                    switch (type) {
                        case 'BOOL':
                            if (typeof defaultValue === 'boolean') {
                                item.valueBool = (defaultValue as boolean);
                            }
                            break;
                        case 'DATE':
                            if (typeof defaultValue === 'number') {
                                item.valueDate = (defaultValue as number);
                            }
                            break;
                        case 'NORMATIVE':
                            if (typeof defaultValue === 'number') {
                                item.valueNormRate = (defaultValue as number);
                            }
                            break;
                        case 'NUMBER':
                            if (typeof defaultValue === 'number') {
                                item.valueNumber = (defaultValue as number);
                            }
                            break;
                        case 'SALARY_RATE':
                            if (typeof defaultValue === 'number') {
                                item.valueSalaryRate = (defaultValue as number);
                            }
                            break;
                        case 'TEXT':
                            if (typeof defaultValue === 'string') {
                                item.valueText = (defaultValue as string);
                            }
                            break;
                        default:
                            break;
                    }
                }

                data.push(item);
            }
        });

        this.loading = true;
        Ax(
            {
                url: `/api/budget/staffing_table/config/${versionId}/ead-c/list`,
                method: 'POST',
                data: data
            },
            () => {
                this.originalKeyMap = new Map(this.keyMap);
                this.originalDefaultValueMap = new Map(this.defaultValueMap);
                this.originalDefaultValueMapForAllowRate = new Map(this.defaultValueMapForAllowRate);
                this.toast('success', this.i18n.translate('title'), this.i18n.translate('saved'));
            },
            error => this.toast('danger', this.i18n.translate('error.cannot_load_items'), error.toString()),
            () => {
                this.loading = false;
            }
        );
    }
    // endregion
}
