























































































































import { Component, Vue } from 'vue-property-decorator';
import { Ax } from '@/utils';
import I18n from '../I18n';
import { salaryForms, store } from '../common';
import { ListItem, ListItemPart, Overlay } from '../components';
import { SingleOrgField } from '../organizations';
import { Comp, Dict, Org, Report, Version } from '../types';
import { SingleVersionField } from '../versions';
import Ead from './Ead.vue';


const i18n = new I18n('modules.budget.staffing_table.config');


@Component({
    components: {
        'ead': Ead,
        ListItem,
        ListItemPart,
        Overlay,
        SingleOrgField,
        SingleVersionField,
    }
})
export default class Page extends Vue {
    // region Lifecycle
    // noinspection JSUnusedLocalSymbols
    private created(): void {
        this.$watch('org', (org: Org | null) => {
            store.org = org;
            this.version = null;
        });
        this.$watch('version', (version: Version | null) => {
            store.version = version;
            this.selectedBudgetProgram = null;
            this.tryReloadSettings(() => {
                this.tryReloadBudgetPrograms();
            });
        });
        this.$watch('selectedBudgetProgramCode', (newValue: number | null) => {
            if (this.settingsBudgetProgram !== newValue) {
                this.settingsBudgetProgram = newValue;
            }
        });
    }

    // noinspection JSUnusedLocalSymbols
    private mounted() {
        this.reset();
    }
    // endregion


    // region Утилиты
    private i18n = i18n;

    private toast(type: 'danger' | 'warning' | 'success', title: string, message: string) {
        this.$bvToast.toast(message, {
            title: title,
            variant: type,
            toaster: 'b-toaster-top-center',
            autoHideDelay: 5000,
            appendToast: true
        });
    }
    // endregion


    // region Организация
    private org: Org | null = store.org;

    private get gu(): Dict.Gu | null {
        if (this.org === null) return null;
        if (this.org.type === 'GU') {
            return this.org.gu;
        } else {
            return null;
        }
    }

    private get guCode(): string | null { return (this.gu?.code ?? null); }

    private get abpCode(): string | null {
        const guCode = this.guCode;
        if ((guCode === null) || (guCode.length < 3)) return null;
        return guCode.substring(0, 3);
    }
    // endregion


    // region Версия ШР
    private version: Version | null = store.version;
    // endregion


    // region Формы расчетов
    private get formOptions(): Array<Comp.DropdownItemDef<Report.FormKey | null>> {
        const result: Array<Comp.DropdownItemDef<Report.FormKey | null>> = salaryForms.map(form => {
            const name = this.i18n.enumTranslate('report_form_key_name', form);
            return {
                text: `${this.i18n.enumTranslate('report_form_key', form)} - ${name}`,
                value: form,
            };
        });
        result.unshift({
            text: '',
            value: null,
        });

        return result;
    }
    // endregion


    // region Бюджетные программы
    private budgetProgramsLoading = false;

    private budgetPrograms: Array<Dict.EbkFunc> = [];

    private get budgetProgramOptions(): Array<Comp.DropdownItemDef<Dict.EbkFunc | null>> {
        const result: Array<Comp.DropdownItemDef<Dict.EbkFunc | null>> = [];
        result.push({
            text: '',
            value: null,
        });

        const isKazakh = this.i18n.isKazakh;
        this.budgetPrograms.forEach(budgetProgram => {
            const title = (isKazakh ? budgetProgram.nameKk : budgetProgram.nameRu) ?? '';

            let code = String(budgetProgram.prg);
            while (code.length < 3) {
                code = '0' + code;
            }

            const option: Comp.DropdownItemDef<Dict.EbkFunc> = {
                text: `${code} - ${title}`,
                value: budgetProgram,
            };

            result.push(option);
        });

        return result;
    }

    private selectedBudgetProgram: Dict.EbkFunc | null = null;

    private get selectedBudgetProgramCode(): number | null {
        const selectedBudgetProgram = this.selectedBudgetProgram;
        if (selectedBudgetProgram === null) return null;
        return selectedBudgetProgram.prg;
    }


    private tryReloadBudgetPrograms() {
        if (this.abpCode === null) return;
        this.reloadBudgetPrograms();
    }

    private reloadBudgetPrograms() {
        if (this.budgetProgramsLoading) {
            console.error('Cannot reload budget programs - another loading is running');
            return;
        }

        const abpCode = this.abpCode;
        if (abpCode === null) {
            console.error('Cannot reload budget programs - ABP code is null');
            return;
        }

        this.budgetPrograms = [];
        this.budgetProgramsLoading = true;
        Ax<Array<Dict.EbkFunc>>(
            { url: `/api/budget/staffing_table/report/budget-programs?abp-code=${abpCode}&date=${Date.now()}` },
            data => {
                const selectedBudgetProgram = data
                    .find(budgetProgram => (budgetProgram.prg === this.settingsBudgetProgram))
                    ?? null;
                this.budgetPrograms = data;
                this.selectedBudgetProgram = selectedBudgetProgram;
            },
            error => this.toast('danger', this.i18n.translate('error.cannot_load_budget_programs'), error.toString()),
            () => { this.budgetProgramsLoading = false; }
        );
    }
    // endregion


    // region Настройки
    private settingsReportForm: Report.FormKey | null = null;
    private settingsBudgetProgram: number | null = null;
    private loadingSettings = false;

    private reloadSettings(next?: (() => void)) {
        if (this.loadingSettings) {
            console.error('Cannot reload settings - another loading is running');
            return;
        }

        const version = this.version;
        if (version === null) {
            console.error('Cannot reload settings - version is null');
            return;
        }

        this.loadingSettings = true;
        Ax<Org.Settings>(
            { url: `/api/budget/staffing_table/gu-settings/${version.id}` },
            data => {
                this.settingsReportForm = data.form;
                this.settingsBudgetProgram = data.budgetProgram;
            },
            error => this.toast('danger', this.i18n.translate('error.cannot_load_settings'), error.toString()),
            () => {
                this.loadingSettings = false;
                if (next) setTimeout(next);
            }
        );
    }

    private tryReloadSettings(next?: (() => void)) {
        if (this.version === null) return;
        this.reloadSettings(next);
    }

    private saveSettings() {
        if (this.loadingSettings) {
            console.error('Cannot save settings - another loading is running');
            return;
        }

        const version = this.version;
        if (version === null) {
            console.error('Cannot save settings - version is null');
            return;
        }

        const versionId = version.id;
        if (versionId === null) {
            console.error('Cannot save settings - version ID is null');
            return;
        }

        const data: Org.Settings = {
            form: this.settingsReportForm,
            budgetProgram: this.settingsBudgetProgram,
        }

        this.loadingSettings = true;

        Ax(
            {
                url: `/api/budget/staffing_table/gu-settings/${versionId}`,
                method: 'POST',
                data,
            },
            () => this.toast('success', this.i18n.translate('settings'), this.i18n.commonTranslate('saved')),
            error => this.toast('danger', this.i18n.translate('error.cannot_load_settings'), error.toString()),
            () => { this.loadingSettings = false; }
        );
    }

    private reset() {
        this.tryReloadSettings(() => {
            this.tryReloadBudgetPrograms();
        });
    }
    // endregion
}
